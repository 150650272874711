export const environment = {
  production: false,
  serviceURL: 'https://listings-poc-service.nsf.org/publiclistingservice/',
  appName: (location.hostname == 'localhost' && location.host != "localhost:8080") ? "" : "/servicename/",
  msalConfig: {
    auth: {
      clientId: 'fc2406c1-1044-4297-ac31-38c3306ab026',
      authority: 'https://login.microsoftonline.com/400696bb-3ef5-44ed-b838-ceb5afd17d90',
      redirectUri:'https://listings-poc.nsf.org/certified-products-systems'  
    }
  },
  apiConfig: {
    scopes: [ 
      'user.read'   
    ],
    uri: 'https://graph.microsoft.com/v1.0/me'
  }
};