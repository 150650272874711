import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-search-result-header',
  templateUrl: './search-result-header.component.html',
  styleUrls: ['./search-result-header.component.scss']
})
export class SearchResultHeaderComponent implements OnInit, OnChanges{  
  @Input() searchResultHeaderInfo : any = "";
  @Input() isResultCategoryExpanded: Boolean = false;
  isExpand : Boolean = true;

  ngOnInit() {
    this.isExpand = this.isResultCategoryExpanded;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isExpand = this.isResultCategoryExpanded;
  }

}
