
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators'
import { BroadcastService } from '../shared/services/broadcast.service';


export class AuthInterceptor implements HttpInterceptor {
   constructor ( private _broadcast: BroadcastService ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError( (error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          // server side error
          if (error.status === 0) {

          } else if (error.status === 400) {

          } else if (error.status === 504) {
            this._broadcast.setErrorMessage({ iShowError: true, message: 'The request is taking more time to process than expected, please try again.' });
            this._broadcast.showLoader(false);
          } else {
            // Default error
          }
          return throwError(error);
        } else {
          // client side error
          const errObject = this.handleClientSideError(error);
          throw errObject;
        }
      })
    )
  }


  private handleClientSideError(error: any): Promise<any> {
    return new Promise(async (res, rej) => {
      const errorObj = {
        errorMsg: "Client-side error",
        error: error
      }
      res(errorObj);
    });
  }

}
