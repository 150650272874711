<div class="sub-header-info">
  <div class="leftBlock">
    <!-- <span class="warning warningLbl" *ngIf="disclaimerType !== 'isSearch'">{{constants.HEADER_WARNING_LBL}}</span> 
     <p class="warning warningTxt" *ngIf="disclaimerType !== 'isSearch'">{{constants.HEADER_WARNING_DISCLAIMER}}</p> -->
    <app-disclaimer [place]="'top'"></app-disclaimer>
  </div>
  <br>
  <div class="rightBlock">
    <a *ngIf="classicViewUrl" href="{{classicViewUrl}}" class="switch-to-cls-btn" role="button">
      <img src="assets/images/classic-view-btn-icon.svg" alt="">
      Back to nsf.org
    </a>
  </div>
  <br>
  <!-- <section class="head-sec">
    <h1>Certified Products and Systems</h1>
    <p>Search for companies whose products and systems have been certified by NSF.</p>
    <a *ngIf="classicViewUrl" href="{{classicViewUrl}}" class="switch-to-cls-btn" role="button">
      <img src="assets/images/classic-view-btn-icon.svg" alt="">
      Switch to Classic View
    </a>
  </section>
  <p class="warning" *ngIf="disclaimerType !== 'isSearch'">{{constants.HEADER_WARNING_DISCLAIMER}}</p>
  <app-disclaimer [place]="'top'"></app-disclaimer> -->
</div>
