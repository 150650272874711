import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LoaderComponent } from './component/loader/loader.component';
import { MainHeaderComponent } from './component/main-header/main-header.component';
import { DynamicFormFieldsComponent } from './component/dynamic-form-fields/dynamic-form-fields.component';
import { InputTextBoxComponent } from './component/dynamic-form-fields/fields/input-text-box/input-text-box.component';
import { InputTextAreaComponent } from './component/dynamic-form-fields/fields/input-text-area/input-text-area.component';
import { InputDatePickerComponent } from './component/dynamic-form-fields/fields/input-date-picker/input-date-picker.component';
import { InputDropDownComponent } from './component/dynamic-form-fields/fields/input-drop-down/input-drop-down.component';
import { InputMultiSelectComponent } from './component/dynamic-form-fields/fields/input-multi-select/input-multi-select.component';
import { InputCheckBoxComponent } from './component/dynamic-form-fields/fields/input-check-box/input-check-box.component';
import { InputRadioButtonComponent } from './component/dynamic-form-fields/fields/input-radio-button/input-radio-button.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { BreadCrumbModule } from '@progress/kendo-angular-navigation';
import { DetailInfoComponent } from './component/detail-info/detail-info.component';
import { ProductDetailsComponent } from '../layout/product-details/product-details.component';
import { SearchResultHeaderComponent } from './component/search-result-header/search-result-header.component';
import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { CommonGridComponent } from './component/common-grid/common-grid.component';
import { MVCommonGridComponent } from './component/mv-common-grid/mv-common-grid.component';
import { HightlightPipe } from './component/common-grid/hightlight.pipe';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { KendoMultiselectDirDirective } from './component/dynamic-form-fields/fields/input-multi-select/kendo-multiselect-dir.directive';
import { ErrorComponent } from './component/error/error.component';
import { NoDataFoundComponent } from './component/no-data-found/no-data-found.component';
import { DisclaimerComponent } from './component/disclaimer/disclaimer.component';
import { NotFoundPage } from './component/not-found-page/not-found.page';
import { SearchRadioComponent } from './component/search-radio/search-radio.component';
import { SharedLinkComponent } from './component/shared-link/shared-link.component';
import { ErrorPageComponent } from './component/error-page/error-page.component';
import { FilterModule } from "@progress/kendo-angular-filter";

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    MainHeaderComponent,
    DynamicFormFieldsComponent,
    InputTextBoxComponent,
    InputTextAreaComponent,
    InputDatePickerComponent,
    InputDropDownComponent,
    InputMultiSelectComponent,
    KendoMultiselectDirDirective,
    InputCheckBoxComponent,
    InputRadioButtonComponent,
    BreadcrumbComponent,
    DetailInfoComponent,
    SearchResultHeaderComponent,
    CommonGridComponent,
    MVCommonGridComponent,
    ErrorComponent,
    HightlightPipe,
    NoDataFoundComponent,
    DisclaimerComponent,
    NotFoundPage,
    SearchRadioComponent,
    SharedLinkComponent,
    ErrorPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    DateInputsModule,
    PopoverModule,
    PopupModule,
    TooltipsModule,
    NotificationModule,
    LabelModule,
    ButtonsModule,
    DialogsModule,
    DropDownsModule,
    BreadCrumbModule,
    GridModule,
    PDFModule,
    FilterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    InputsModule,
    DateInputsModule,
    PopupModule,
    LabelModule,
    ButtonsModule,
    DialogsModule,
    TooltipsModule,
    DropDownsModule,
    ReactiveFormsModule,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    KendoMultiselectDirDirective,
    DynamicFormFieldsComponent,
    MainHeaderComponent,
    BreadcrumbComponent,
    DetailInfoComponent,
    SearchResultHeaderComponent,
    CommonGridComponent,
    MVCommonGridComponent,
    HightlightPipe,
    ErrorComponent,
    NoDataFoundComponent,
    DisclaimerComponent,
    NotFoundPage,
    SearchRadioComponent,
    SharedLinkComponent,
    DisclaimerComponent,
    ErrorPageComponent
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]

})
export class SharedModule { }
