<kendo-formfield [formGroup]="formGroup" orientation="horizontal">
  <div class="k-form-field-checkbox-wrap">
    <input kendoCheckBox [id]="field.field_name" type="checkbox" [id]="field.field_name" class="dynamicCheckbox"
      [formControlName]="field.field_name" (change)="valueChange($event,field)" />
    <kendo-label class="k-checkbox-label" [for]="field.field_name" [text]="field.field_heading"
      [ngClass]="{'disableField': isFieldDisabled == true}"><span *ngIf="field.mandatory_flg === 'Y'"
        class='primaryIndicator'>*</span>
    </kendo-label>
  </div>
</kendo-formfield>
