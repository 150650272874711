<div class="disclaimer-block" [ngClass]="{'header' : place === 'top', 'footer' : place === 'bottom'}"
  *ngIf="disclaimerType">
  <div *ngIf="(disclaimerType === 'isSearch' || disclaimerType === 'isDefault') && place === 'bottom'">
    <p>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_1}}</span>
      <a
        href="https://www.nsf.org/about-nsf/regulatory-affairs/enforcement-actions">{{constants.FOOTER_DISCLAIMER_PARA_1_LINK}}</a>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_1_2}}</span>
    </p>
    <p>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_2}}</span>
      <a href="https://www.nsf.org/about-nsf/nsf-mark">{{constants.FOOTER_DISCLAIMER_PARA_2_LINK}}</a>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_2_2}}</span>
    </p>
    <p>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_3}}</span>
      <a href="mailto:CertRec@nsf.org">{{constants.FOOTER_DISCLAIMER_PARA_3_LINK_1}}</a>
      <span>.</span>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_3_2}}</span>
      <a href="mailto:info@nsf.org">{{constants.FOOTER_DISCLAIMER_PARA_3_LINK_2}}</a>
      <span>.</span>
    </p>
  </div>
  <div *ngIf="disclaimerType === 'ProductDetails' && place === 'bottom'">
    <p>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_4}}</span>
      <b>{{commonservice.apiDateTime.listingDetailsUpdateDate}}</b>
      <span>{{constants.HEADER_DISCLAIMER_PARA_1_2}}{{commonservice.apiDateTime.listingDetailsUpdateTime}}{{constants.HEADER_DISCLAIMER_PARA_1_3}}</span>
    </p>
    <p>
      <span>{{constants.FOOTER_DISCLAIMER_PARA_5}}</span>
    </p>
  </div>
  <div *ngIf="disclaimerType === 'isSearch' && place === 'top'">
    <p>
      <span>{{constants.HEADER_DISCLAIMER_PARA_1}}</span>
      <b>{{commonservice.apiDateTime.listingDetailsUpdateDate}}</b>
      <span>{{constants.HEADER_DISCLAIMER_PARA_1_2}}{{commonservice.apiDateTime.listingDetailsUpdateTime}}{{constants.HEADER_DISCLAIMER_PARA_1_3}}</span>
    </p>
    <p>
      <span>{{constants.HEADER_DISCLAIMER_PARA_2}}</span>
      <a [href]="constants.HEADER_DISCLAIMER_PARA_2_LINK">{{constants.HEADER_DISCLAIMER_PARA_2_LINK}}</a>
    </p>
    <!-- <p class="warning">{{constants.HEADER_WARNING_DISCLAIMER}}</p> -->
  </div>
</div>
