import { Component, EventEmitter, OnChanges, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { BroadcastService } from '../../services/broadcast.service';

@Component({
  selector: 'app-dynamic-form-fields',
  templateUrl: './dynamic-form-fields.component.html',
  styleUrls: ['./dynamic-form-fields.component.scss']
})
export class DynamicFormFieldsComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions = new Subscription();
  @Output() formOutputValue = new EventEmitter();
  formGroup!: FormGroup;
  formFields: any[] = [];
  @Input() isReset: number = 0;
  @Input() isSubmit: any;
  @Input() set formData(data: any) {
    this.initializedata(data);
  }
  isUpdateFields: number = 0;

  constructor(public formBuilder: FormBuilder, private _broadcast: BroadcastService,
    public _api: ApiService) {
    this.formGroup = new FormGroup({});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isReset']) {
      this.formGroup.reset();
    }
  }

  initializedata(data: any) {
    const header = this._api.getaccessToken();
    let fieldArray: any = [...data];
    let apiList: any = [];
    let indexVal: any = []
    fieldArray.forEach((ele: any, index: any) => {
      if (ele.valueAPIRelatvieRef && (!ele.dropdown_list || ele.dropdown_list.length === 0)) {
        apiList.push(this._api.get(ele.valueAPIRelatvieRef, header));
        indexVal.push(index);
      }
    });
    if (apiList.length > 0) {
      this._broadcast.showLoader(true);
      this.apiToGetData(apiList, indexVal, fieldArray);
    } else {
      this.formFields = fieldArray;
      this.generateForm();
    }
  }

  apiToGetData(apiList: any, JsonData: any, fieldArray: any): void {
    forkJoin(apiList).subscribe((resposneData: any) => {
      resposneData.forEach((res: any, index: number) => {
        if (res) {
          fieldArray[Number(JsonData[index])].dropdown_list = res.data
        }
      });
      this._broadcast.showLoader(false);
      this.formFields = fieldArray;
      this.generateForm();
    });
  }

  generateForm() {
    const controls: any = {};
    this.formFields?.forEach((col: any) => {
      if (col.mandatory_flg === 'Y' && (col.field_type === 'STRING' || col.field_type === 'VARCHAR')) {
        controls[col.field_name] = new FormControl(col.field_value ? col.field_value : '', [Validators.required, Validators.pattern(/[a-zA-Z0-9^]/), Validators.maxLength(col.max_field_length)]);
      } else if (col.mandatory_flg === 'Y' && col.field_type === 'NUMBER') {
        controls[col.field_name] = new FormControl(col.field_value ? col.field_value : '', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(col.max_field_length)]);
      }
      else if (col.mandatory_flg === 'Y' && col.field_type === 'MULTISELECT') {
        controls[col.field_name] = new FormControl(col.field_value ? col.field_value : '', [Validators.required]);
      }
      else if (col.field_data_type === 'DATE') {
        controls[col.field_name] = new FormControl(col.field_value ? new Date(col.field_value) : null);
      } else {
        controls[col.field_name] = new FormControl(col.field_value ? col.field_value : '');
      }
    });
    this.formGroup = new FormGroup(controls);
    const fieldDetails = {
      "currentFieldValue": "",
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": ""
    }
    this.formOutputValue.emit([fieldDetails, this.formGroup]);
    setTimeout(() => { this.patchValueToForm(); }, 0)
  }

  ngOnInit(): void {
    this.subscriptions.add(this._broadcast.getUpdatedAdvFormFn().subscribe((data: any) => {
      if (data.length > 0) {
        data.forEach((ele: any) => {
          if (ele.field_data_type === 'DATE') {
            if (ele.field_value) {
              this.formGroup?.controls[ele.field_name]?.setValue(new Date(ele.field_value) || null);
            }
          } else {
            this.formGroup?.controls[ele.field_name]?.setValue(ele.field_value || null);
          }
        });
        this.isUpdateFields++;
      }
    }));
  }

  updatedFormGroupVal(value: any): void {
    if (value.currentFieldInfo) {
      value.currentFieldInfo.field_value = value.currentFieldValue;
      this.formOutputValue.emit([value, this.formGroup]);
    }
  }

  patchValueToForm() {
    this.formFields?.forEach((ele: any, index: any) => {
      if (ele.field_value) {
        if (ele.field_data_type === 'DATE') {
          if (ele.field_value) {
            this.formGroup?.controls[ele.field_name]?.setValue(new Date(ele.field_value) || null);
          }
        } else {
          this.formGroup?.controls[ele.field_name]?.setValue(ele.field_value || null);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
