<div class="example-wrap">
  <div [formGroup]="formGroup" class="k-form k-form-horizontal" id="advancedFilter">
    <ng-container *ngFor="let field of formFields">
      <ng-container [ngSwitch]="field.field_data_type">
        <div class="formBlock" [ngStyle]="{'width': field.width, 'margin-left': field.marginLeft, 'margin-right': field.marginRight}">
          <app-input-text-box class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'TEXT'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-text-box>
          <app-input-date-picker *ngSwitchCase="'DATE'" [field]="field" Class="col-12 col-md-6 col-lg-4 px-2"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-date-picker>
          <app-input-text-area class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'TEXTAREA'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-text-area>
          <app-input-drop-down class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'DROPDOWN'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-drop-down>
          <app-input-multi-select class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'MULTISELECT'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-multi-select>
          <app-input-auto-complete class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'AUTOCOMPLETE'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-auto-complete>
          <app-input-numeric-textbox class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'NUMBER'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-numeric-textbox>
          <app-input-check-box class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'CHECKBOX'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-check-box>
          <app-input-radio-button class="col-12 col-md-6 col-lg-4 px-0" *ngSwitchCase="'RADIO'" [field]="field"
            [formGroup]="formGroup" (formGroupValue)="updatedFormGroupVal($event)" [isUpdateFields]="isUpdateFields">
          </app-input-radio-button>
        </div>
        <!-- <kendo-formerror *ngIf="isSubmit && formGroup.controls[field.field_name].errors && formGroup.controls[field.field_name].errors['required']">
            Error:{{field.field_name}} is required</kendo-formerror> -->
      </ng-container>
    </ng-container>
  </div>
</div>
