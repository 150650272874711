import { Component, Input } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../services/broadcast.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent {
  private subscriptions = new Subscription();
  readonly constants = AppConstants;
  disclaimerType: string = 'isDefault';
  @Input() place: string;

  constructor(private _broadcast: BroadcastService,
    public commonservice: CommonService) {
  }

  ngOnInit() {
    this.subscriptions.add(this._broadcast.getRedirect().subscribe((data: any) => {
      switch (data) {
        case 'search-result':
          this.disclaimerType = 'isSearch';
          break;
        case 'product-details':
          this.disclaimerType = 'ProductDetails';
          break;
        case 'model-details':
          this.disclaimerType = 'ProductDetails';
          break;
        default:
          this.disclaimerType = 'isDefault';
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
