import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-input-drop-down',
  templateUrl: './input-drop-down.component.html',
  styleUrls: ['./input-drop-down.component.scss']
})
export class InputDropDownComponent implements OnInit {
  public isActive = new Subject();
  defaultVal: any;
  dropdownField: any;
  isFieldDisabled: boolean = false;
  @Input() isUpdateFields: number = 0;
  @Input() set field(val: any) {
    this.dropdownField = val;
    if (!val?.hasDefaultValue) {
      this.defaultVal = this.defaultItem(val);
    }
  }

  @Input() formGroup!: FormGroup;
  public levels: any = {};
  public divisions: any = {};
  @Output() formGroupValue = new EventEmitter();
  isShow: boolean = false;
  division: any = [];
  label: any;
  dropDowndata: any;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isUpdateFields'] && !changes['isUpdateFields'].firstChange) {
      this.dropDowndata = this.dropdownField?.dropdown_list.slice();
      let hasValue = false;
      this.dropDowndata.forEach((ele: any) => {
        if (ele.key === this.dropdownField.field_value) {
          hasValue = true;
        }
      });
      if (!hasValue) {
        this.dropdownField.field_value = null;
        let jsonData = JSON.parse(sessionStorage.getItem('advFilter') || '{}');
        if (jsonData !== null) {
          jsonData[this.dropdownField.field_name] = null;
        }
      }
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.isFieldDisabled = this.formGroup.controls[this.dropdownField.field_name].disabled;
    let division = {};
    this.dropDowndata = this.dropdownField?.dropdown_list.slice();
  }

  valueChange(event: any, currentField: any): void {
    let jsonData = JSON.parse(sessionStorage.getItem('filteredDataVal') || '{}');
    this.dropdownField.dropdown_list.forEach((item: any) => {
      if (item.key === event) {
        jsonData[this.dropdownField.field_name] = item.value;
      }
    });
    sessionStorage.setItem('filteredDataVal', JSON.stringify(jsonData));

    this.formGroupValue.emit({
      "currentFieldValue": event,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": currentField
    });
  }
  defaultItem(field: any) {
    if (field.field_name.toLowerCase().includes('year')) {
      return {
        value: 'Select Year',
        key: null,
      };
    } else if (field.field_name.toUpperCase().includes('ROUND')) {
      return {
        value: 'Select Round',
        key: null,
      };
    } else if (field.snapshot_dropdown_desc) {
      return {
        value: field.snapshot_dropdown_desc,
        key: null,
      };
    }
    else {
      return {
        value: 'Select',
        key: null,
      };
    }
  }

  handleFilter(value: any) {
    let dropDowndata = this.dropdownField.dropdown_list.filter(
      (s: any) => s.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.dropDowndata = dropDowndata;
  }

}
