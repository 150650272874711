<div class="radioClass">
  <kendo-formfield [formGroup]="formGroup" orientation="horizontal">
    <div class="k-form-field-radio-wrap" *ngFor="let list of field.dropdown_list">
      <input kendoRadioButton [id]="list.value" type="radio" [value]="list.value"
        (change)="valueChange(list.value, field)" [formControlName]="field.field_name" 
        [ngClass]="list.selected === 'Y' ? 'k-checked' : ''"/>
      <kendo-label class="k-radio-label radioLabel" [for]="list.value" [text]="list.label">
      </kendo-label>
    </div>
    <kendo-label class="k-radio-label" [for]="field.dropdown_list" [text]="field.field_heading"
      aria-label="getLabels(field)"><span *ngIf="field.mandatory_flg === 'Y'" class='primaryIndicator'>*</span>
    </kendo-label>
  </kendo-formfield>
</div>
