import { Component, Input } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../services/broadcast.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  // private subscriptions = new Subscription();
  // @Input() isDefault = true;
  // @Input() message = '';
  // readonly constants = AppConstants;

  constructor(private _broadcast: BroadcastService,
    public commonservice: CommonService) { }

  ngOnInit(): void {
    // this.subscriptions.add(this._broadcast.getNoDataImage().subscribe((data: any) => {
    //   alert();
    //   this.isDefault = data.iSDefault;
    //   this.message = data.message;
    //   setTimeout(() => {
    //     this.commonservice.findCategoryMinHeight();
    //   }, 500);
    // }));
  }

  ngOnDestroy() {
   // this.subscriptions.unsubscribe();
  }
}
