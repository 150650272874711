import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-radio-button',
  templateUrl: './input-radio-button.component.html',
  styleUrls: ['./input-radio-button.component.scss']
})
export class InputRadioButtonComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field: any;
  @Output() formGroupValue = new EventEmitter();
  label: any;

  ngOnInit(): void {
    // console.log(JSON.stringify(this.field));
  }

  valueChange(event: any, currentField: any): void {
    currentField.dropdown_list.forEach((radioVal: any) => {
      if (radioVal.value === event) {
        radioVal.selected = 'Y';
      } else {
        radioVal.selected = 'N';
      }
    });
    this.formGroupValue.emit({
      "currentFieldValue": event,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": currentField
    });
  }

}
