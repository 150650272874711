import { Component, Inject } from '@angular/core';
import { BroadcastService } from './shared/services/broadcast.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'publiclisting';
  loader: boolean = false;
 // isIframe = false;
  loginDisplay = false;
  public host:any = window.location.host
  private readonly _destroying$ = new Subject<void>();

  constructor(private _broadcast:BroadcastService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService) {}

  ngOnInit() {   
    this._broadcast.isLoader.subscribe((res: boolean) => {
      this.loader = res;
    });
    
    // Enable this for SSO - 14532 
    if( (this.host == "listings-test.nsf.org") || (this.host == "listings-poc.nsf.org") || (this.host == "localhost:3000")){
      this.authService.handleRedirectObservable().subscribe();
    }      

  }
}
