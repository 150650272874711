import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-text-area',
  templateUrl: './input-text-area.component.html',
  styleUrls: ['./input-text-area.component.scss']
})
export class InputTextAreaComponent {
  @Input() formGroup!: FormGroup;
  @Input() field: any;
  @Output() formGroupValue = new EventEmitter();
  label: any;

  valueChange(event: any, currentField: any): void {
    this.formGroupValue.emit({
      "currentFieldValue": event,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": currentField
    });
  }

  // On paste restriction while copiying
  onPaste(name: string, event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const val = clipboardData?.getData('text');
    event.preventDefault();
    let finalValue: any = '';
    if (val?.length !== 0) {
      finalValue = val?.replace(/^(0[1-9]|1[0-2])\/\d{4}$/g, '');

    }
    if (finalValue) {
      this.formGroup.controls[this.field.field_name].setValue(finalValue);
      this.formGroupValue.emit({
        "currentFieldValue": finalValue,
        "allFieldValue": this.formGroup.value,
        "currentFieldInfo": this.field
      });
    }
  }

  // on Trim functionality
  onTrim(name: any, event: any): any {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

}
