<div class="show-results">
  <div class="search-header">
    <p>{{constants.SHOW_RESULTS}}</p>
    <div class="radio-block">
      <a href="javascript:void(0)" (click)="radioClick('products')">
        <img [src]="searchType === 'products'?'assets/images/radioSelected.svg' : 'assets/images/radioUnselect.svg'" alt="radio" />
        <span>{{constants.PRODUCT}}</span>
      </a>

      <a href="javascript:void(0)" (click)="radioClick('companies')">
        <img [src]="searchType === 'companies'?'assets/images/radioSelected.svg' : 'assets/images/radioUnselect.svg'" alt="radio" />
        <span>{{constants.COMPANIESLBL}}</span>
      </a>
    </div>
  </div>
</div>
