<div class="no-data-block">
  <div class="no-data">
    <!-- <img [src]="isDefault ? 'assets/images/noSearchData.svg' : 'assets/images/noSearchDataNew.svg'" alt="No Data" /> -->
    <!-- <div *ngIf="isDefault">
      <div><span>{{constants.NO_DATA_FOUND_DEFAULT}}</span></div>
    </div> -->
    <img *ngIf="!isDefault" [src]="'assets/images/noSearchDataNew.svg'" alt="No Data" />
    <div *ngIf="!isDefault">
      <div><span class="msg">{{constants.NO_DATA_FOUND_1}}</span></div>
      <div *ngIf="!message"><span>{{constants.NO_DATA_FOUND_2}}</span></div>
      <div *ngIf="message"><span>{{message}}</span></div>
      <!-- <div><span>{{}}</span></div> -->
    </div>
  </div>
</div>
