import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BroadcastService } from './broadcast.service';
import { AppConstants } from '../constants/app.constants';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class PerformsearchService {
  readonly constants = AppConstants;
  isAPIProgress: boolean = false;

  constructor(private _api: ApiService,
    private _broadcast: BroadcastService) { }

   
    isValid(exclusiveSearchFiltersVar: any[]) {
      let isValid = false;    
      exclusiveSearchFiltersVar.forEach(parameter => {
        if (parameter.isEditable === "Y" || parameter.isEditableValue === null || parameter.isEditableValue === "") {
          isValid = true;
        }
      });    
      return isValid;
    }

    separatedAndBalancedFilters(exclusiveSearchFiltersVar: any[]){
      const separatedAndBalancedFilters = exclusiveSearchFiltersVar.filter(parameter => {
        // Check if the parameter is editable or has a null/empty editable value
        const isEditable = parameter.isEditable === "Y" || parameter.isEditableValue === null || parameter.isEditableValue === "";
      
        // If the parameter is editable, return it as a separate element
        if (isEditable) {
          return {
            ...parameter,
            isExclusiveFilter: "N" // Set isExclusiveFilter to "N" for editable parameters
          };
        } else {
          // If the parameter is not editable, return it as part of a balanced pair
          return {
            ...parameter,
            isExclusiveFilter: "Y" // Keep isExclusiveFilter as "Y" for non-editable parameters
          };
        }
      });

      sessionStorage.setItem('exclusiveYFilterParameters',JSON.stringify(separatedAndBalancedFilters));              
     
    }
   
   
  getSearchResults() {
    this.isAPIProgress = true;
    const advSearchType = sessionStorage.getItem("advSearchType");
    let advFilter = JSON.parse(sessionStorage.getItem("advFilter") || 'null');
    let dynamicFieldsJSON = JSON.parse(sessionStorage.getItem("sectionData") || 'null');   
    let searchParameters: any = [];
    let exclusiveSearchParameters: any = [];
    let selectedRegion: any[] = [];
    let selectedCountryNState: any[] = [];
    if (advFilter?.facilityLocation && advFilter?.facilityLocation.length > 0) {
      if (advSearchType === 'region') {
        advFilter?.facilityLocation?.forEach((region: any) => {
          const regionObj = {
            code: region.key,
            name: region.value
          }
          selectedRegion.push(regionObj);
        });
      } else {
        advFilter?.facilityLocation?.forEach((country: any) => {
          const countryObj = {
            code: country.key,
            name: country.value,
            state: [] as any
          }
          if (advFilter?.state?.length > 0) {
            advFilter.state.forEach((state: any, index: number) => {
              if (country.key === state.key.split(',')[1]) {
                const stateObj = {
                  code: state.key.split(',')[0],
                  name: state.value,
                }
                countryObj.state.push(stateObj);
              }
            });
          }
          selectedCountryNState.push(countryObj);
        });
      }
    }
    if (Array.isArray(dynamicFieldsJSON)) {
      console.log("dynamicFieldsJSON",dynamicFieldsJSON)
      dynamicFieldsJSON.forEach((dynamicField: any) => {
        let searchParam: { parameterId: string, value: string[], parameterType: string,additionalFields:string,isExclusiveFilter: any,isEditable: any,displayName:string  } = {
          "parameterId": "",
          "value": [],
          "parameterType": "",
          "additionalFields":"",
          "isExclusiveFilter":"",
          "isEditable":"",
          "displayName":""
        }
        let exclusiveSearchParameter: { parameterId: string, value: string[], parameterType: string,additionalFields:string,isExclusiveFilter: any,isEditable: any,displayName:string } = {
          "parameterId": "",
          "value": [],
          "parameterType": "",
          "additionalFields":"",
          "isExclusiveFilter":"",
          "isEditable":"",
          "displayName":""
        }
        if (dynamicField.field_value != undefined && dynamicField.field_value != "") {
          if (dynamicField.field_data_type != 'MULTISELECT') {
              searchParam.parameterId = dynamicField.field_name,
              searchParam.value.push(dynamicField.field_value),
              searchParam.parameterType = dynamicField.field_data_type
              searchParam.additionalFields = dynamicField.additionalFields
              searchParam.isExclusiveFilter = dynamicField.isExclusiveFilter
              searchParam.isEditable = dynamicField.isEditable
              searchParam.displayName = dynamicField.displayName

              exclusiveSearchParameter.parameterId = dynamicField.field_name
              exclusiveSearchParameter.value.push(dynamicField.field_value)
              exclusiveSearchParameter.parameterType = dynamicField.field_data_type
              exclusiveSearchParameter.additionalFields = dynamicField.additionalFields
              exclusiveSearchParameter.isExclusiveFilter = dynamicField.isExclusiveFilter
              exclusiveSearchParameter.isEditable = dynamicField.isEditable
              exclusiveSearchParameter.displayName = dynamicField.displayName
          } else if (dynamicField.field_data_type == 'MULTISELECT') {
            searchParam.parameterId = dynamicField.field_name,
              dynamicField.field_value.forEach((fieldValue: any) => {
                searchParam.value.push(fieldValue.key)
              })
            searchParam.parameterType = dynamicField.field_data_type
            searchParam.additionalFields = dynamicField.additionalFields
            searchParam.isExclusiveFilter = dynamicField.isExclusiveFilter
            searchParam.isEditable = dynamicField.isEditable
            searchParam.displayName = dynamicField.displayName,

            exclusiveSearchParameter.parameterId = dynamicField.field_name,            
            dynamicField.field_value.forEach((fieldValue: any) => {
              exclusiveSearchParameter.value.push(fieldValue.key)
            })
            exclusiveSearchParameter.parameterType = dynamicField.field_data_type
            exclusiveSearchParameter.additionalFields = dynamicField.additionalFields
            exclusiveSearchParameter.isExclusiveFilter = dynamicField.isExclusiveFilter
            exclusiveSearchParameter.isEditable = dynamicField.isEditable
            exclusiveSearchParameter.displayName = dynamicField.displayName
            
          }
        }
        if (searchParam.parameterId != "" && searchParam.value.length > 0) {
          searchParameters.push(searchParam);
        }
        if (exclusiveSearchParameter.parameterId != "" && exclusiveSearchParameter.value.length > 0) {
           exclusiveSearchParameters.push(exclusiveSearchParameter);
        }
      })
    }
    const header = this._api.getPlainHeader();

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let request = {};
        let searchResult = JSON.parse(sessionStorage.getItem("searResult") || 'null');
        const inputData = exclusiveSearchParameters    
        const exclusiveYFilterParameters = inputData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "Y");             
        this.separatedAndBalancedFilters(exclusiveYFilterParameters);        
        const inputSearchData = searchParameters    
        const exclusiveNFilterParameters = inputSearchData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "N");
         
        if (searchResult !== null) {
          request = searchResult;
        } else {
          let searchType = sessionStorage.getItem('searchType');
          request = {
            "searchText": sessionStorage.getItem("searchfeild"),
            "category": advFilter?.category || null,
            "subCategory": advFilter?.subCategory || null,
            "standPrgGrp": advFilter?.standard || null,
            // "company": searchType === 'companies' ? sessionStorage.getItem("searchfeild") : advFilter?.company || null,
            "company": advFilter?.company || null,
            "location": advFilter?.location || null,
            "searchParameters": exclusiveNFilterParameters,
            "exclusiveSearchParameters":this.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters :  [],
            "region": selectedRegion.length > 0 ? selectedRegion : null,
            "country": selectedCountryNState.length > 0 ? selectedCountryNState : null,           
          }
        }

        let url: string = '';
        if (sessionStorage.getItem('searchType') === 'companies') {
          url = this._api.urls.getCompanySearchResult;
        } else {
          url = this._api.urls.getSearchResult;
        }
        sessionStorage.setItem("searchRequest", JSON.stringify(request));
        this._api.post(url, request, header).subscribe((response: any) => {
          let searchResults: any = response;
          this.isAPIProgress = false;
          sessionStorage.removeItem('searResult');
          //sessionStorage.removeItem('gridFilter');
          if (response) {
            resolve({
              searchResults: searchResults,
              success: response.success
            })
            this._broadcast.setSearchResults(searchResults);
          }
        }, (err) => {
          // console.log('Server Error', err);
          reject({ status: this.constants.ERROR });
                })
      }, 0);
    });
  }
}
