<kendo-formfield [formGroup]="formGroup" orientation="horizontal" class="formField-div"
[ngStyle]="{'opacity': isDisabled === true ? 0.4 : 1 }">
  <div role="text">
    <kendo-label [for]="field.field_name" [text]="field.field_heading" class="input-multiselect-label">
      <span *ngIf="field.mandatory_flg === 'Y'" class='primaryIndicator'>*</span>
    </kendo-label>
  </div>

  <div class="view-details">
    <div class="wrapper">
      <div *ngIf="fieldValue?.length > 0" kendoPopoverContainer [popover]="info" filter=".has-popover" class="wrapper"
        #anchor #container="kendoPopoverContainer" showOn="none" (click)="onToggle(anchor)">View Items</div>
    </div>

    <kendo-popover #info position="top" [width]="300">
      <ng-template kendoPopoverTitleTemplate>
        <div class="prevent popover-title" #head>
          Selected Items
        </div>
      </ng-template>

      <ng-template kendoPopoverBodyTemplate>
        <div class="prevent">
          <div class="prevent selected-list" *ngFor="let data of fieldValue" #body>
            <div class="prevent selected-data">
              <p class="prevent">{{data.value}}</p>
              <span (click)="removeData(data)" class="prevent k-icon k-i-close"></span>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-popover>
  </div>
  <kendo-multiselect kendoMultiSelectSummaryTag appKendoMultiselectDir #multiselect
    [formControlName]="field.field_name"
    class="dynamicMultiSelect inputSelect" [data]="dropDowndata" [textField]="'value'" [valueField]="'key'"
    (valueChange)="valueChange($event,field)" [placeholder]="'Select'" [filterable]="true" [autoClose]="false"
    [id]="field.field_name" (filterChange)="handleFilter($event)" [ngClass]="isDisabled === true ? 'disableEvent' : ''">
    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
      <div class="sample">
        {{ dataItems.length }} item(s) selected
      </div>
    </ng-template>
    <ng-template kendoMultiSelectHeaderTemplate>
      <div class="dropdown-block">
        <span>
          <a href="javascript:void(0)" (click)="onClick()">{{ selectDeSelectAll }}</a>
        </span>
      </div>
    </ng-template>
    <ng-template kendoMultiSelectItemTemplate let-dataItem>
      <div class="dropdown-list-menu">
        <span>
          <kendo-label for="chk-{{ dataItem.key }}">
            <input type="checkbox" id="chk-{{ dataItem.key }}" kendoCheckBox [checked]="isItemSelected(dataItem)" />
          </kendo-label>
        </span>
        <span>{{ dataItem.value }}</span>
      </div>
    </ng-template>
  </kendo-multiselect>
</kendo-formfield>
