import { Directive, ElementRef, EventEmitter, Host, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpanColumnComponent } from '@progress/kendo-angular-grid';

@Directive({
  selector: '[appKendoMultiselectDir]'
})

export class KendoMultiselectDirDirective {
  constructor(public el: ElementRef) {
    setTimeout(() => {
      const elementwrapper = document.querySelectorAll('.k-multiselect');
      elementwrapper.forEach(element => {
        if (element.getElementsByTagName('span').length === 0) {
          const span = document.createElement('span');
          span.classList.add('k-button-icon', 'k-icon', 'k-i-arrow-s', 'mouse-cursor', 'arrow-button');
          // span.addEventListener('click', this.emitEvent);
          element.append(span);
        }
      });

    }, 0);
  }
  @HostListener("ngModelChange", ["$event"]) onNgModelChange(value: any) {
    if (value && value.length > 0) {
      let element = this.el.nativeElement.getElementsByClassName('k-i-arrow-s');
      if (element && element.length > 0) {
        element[0].remove()
      }
    } else {
      let element = this.el.nativeElement.getElementsByClassName('k-i-arrow-s');
      if (element && element.length == 0) {
        const span = document.createElement('span');
        span.classList.add('k-button-icon', 'k-icon', 'k-i-arrow-s', 'mouse-cursor', 'arrow-button');
        this.el.nativeElement.append(span);
      }
    }
  }
}





