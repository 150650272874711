import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'app-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss']
})
export class InputDatePickerComponent {
  @Input() formGroup!: FormGroup;
  @Input() field: any;
  @Input() calenderType: 'classic' | 'infinite' = 'classic';
  @Output() formGroupValue = new EventEmitter();
  @Input() formFieldsList: any;
  dateFormat: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy',
  };
  label: any;

  constructor(private datePipe: DatePipe, private cdr: ChangeDetectorRef) { }

  valueChange(event: any, currentField: any) {
    this.formGroupValue.emit({
      'currentFieldValue': this.datePipe.transform(event, 'dd-MMM-yyyy'),
      'allFieldValue': this.formGroup.value,
      'currentFieldInfo': currentField
    });
  }

}
