import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-text-box',
  templateUrl: './input-text-box.component.html',
  styleUrls: ['./input-text-box.component.scss']
})
export class InputTextBoxComponent {
  @Input() formGroup!: FormGroup;
  @Input() field: any;
  @Input() isFieldDisabled: boolean = false;
  @Output() formGroupValue = new EventEmitter();
  label: any;

  constructor() { }

  valueChange(event: any, currentField: any): void {
    this.formGroupValue.emit({
      "currentFieldValue": event,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": currentField
    });
  }

  // On paste restriction while copiying
  onPaste(name: any, event: ClipboardEvent): void {
    if (name == 'PHONE_NO') {
      const clipboardData = event.clipboardData;
      const val = clipboardData?.getData('text');
      event.preventDefault();
      let finalValue: any = '';
      if (val?.length !== 0) {
        finalValue = val?.replace(/\D+/g, "");
      }
      if (finalValue) {
        finalValue = finalValue.trimStart();
        this.formGroup.controls[this.field.field_name].setValue(finalValue);
        this.formGroupValue.emit({
          "currentFieldValue": finalValue,
          "allFieldValue": this.formGroup.value,
          "currentFieldInfo": this.field
        });
      }
    }
  }

  keyDownFunction(event: any, field: any) {
    if (field == 'USER.PHONE_NO' || field == 'Phone No') {
      const code = event.charCode;
      if ((code >= 43 && code <= 57 && code !== 44 && code !== 46 && code !== 47) || code == 45 || code == 43) {
        return true;
      }
      else {
        return false
      }
    } else {
      return true;
    }
  }

}
