<div class="sub-header">
  <div class="header-info">
    <div class="column" [ngStyle]="{'width': colStyle[0].width, 'margin-right': colStyle[0].marginright}"  *ngFor="let header of headers; let i = index">
      <div>
        <div
          *ngIf="header.FIELD != 'NAME' && header.FIELD != 'CONTACT' && header.FIELD != 'CERTIFICATE_NUMBER' && header.FIELD != 'END_USES'  && header.FIELD != 'WATER_CONTACT_TEMP'  && header.FIELD != 'WATER_CONTACT_MATERIAL' && header.FIELD != 'FOR_USE_WITH' && header.FIELD != 'MAX_PRESSURE' && header.FIELD != 'MAX_TEMP' && header.FIELD != 'TRADE_DESIGNATION' && header.FIELD != 'PRODUCT_DESCRIPTION'">
          <p>{{header.TITLE}}</p>
          <p *ngIf="info[header.FIELD] !=null;then content else nocontent">
          </p>
          <ng-template #content>
            <label>
              {{info[header.FIELD]}}
            </label>
          </ng-template>
          <ng-template #nocontent>
            
          </ng-template>
        </div>
        <div *ngIf="header.FIELD == 'NAME' && info?.NAME != null">
          <p>{{header.TITLE}}</p>
            <span *ngFor="let com_add of info.NAME">
              <label>{{com_add}}</label><br>
            </span>
          </div>
        <div *ngIf="header.FIELD == 'CONTACT' && (info.CONTACT != null || info.COMPANY_CONTACT_URL != null)">
          <p>{{header.TITLE}}</p>
          <span *ngIf="info.CONTACT != null">
            <span *ngFor="let contact of contacts">
              <label>{{contact}}</label><br>
            </span>
          </span>
          <label *ngIf="info.COMPANY_CONTACT_URL && info.COMPANY_CONTACT_URL != null">
            <a (click)="goToWebsite(info.COMPANY_CONTACT_URL)" style="font-family: Inter, sans-serif;
            color: #2d74da; cursor: pointer;">Visit this Company's Website</a>
          </label>
        </div>

        <div *ngIf="header.FIELD == 'TRADE_DESIGNATION'">
          <p>{{header.TITLE}}</p>
          <label class="tradeName" *ngIf="info.LOGO_URL != null">
            <img class="padding9 cursor-pointer" [src]="info.LOGO_URL" />
          </label>
          <label *ngIf="info.LOGO_URL == null">
            {{info[header.FIELD]}}
          </label>
        </div>

        <div *ngIf="header.FIELD == 'END_USES' && header.DATA_TYPE === 'HYPERLINK'">
          <p>{{header.TITLE}}</p>        
          <label class="grid-atag" (click)="windowClickEvt(header.FIELD)">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="header.DATA_TYPE != 'HYPERLINK'">
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'END_USES' && header.DATA_TYPE != 'HYPERLINK'">
          <p>{{header.TITLE}}</p>
          <label>
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'WATER_CONTACT_TEMP' && header.DATA_TYPE === 'HYPERLINK'">
          <p>{{header.TITLE}}</p>        
          <label class="grid-atag" (click)="windowClickEvt(header.FIELD)">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="header.DATA_TYPE != 'HYPERLINK'">
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'WATER_CONTACT_TEMP' && header.DATA_TYPE != 'HYPERLINK'">
          <p>{{header.TITLE}}</p>        
          <label>
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'WATER_CONTACT_MATERIAL' && header.DATA_TYPE === 'HYPERLINK'">
          <p>{{header.TITLE}}</p>        
          <label class="grid-atag" (click)="windowClickEvt(header.FIELD)">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="header.DATA_TYPE != 'HYPERLINK'">
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'WATER_CONTACT_MATERIAL' && header.DATA_TYPE != 'HYPERLINK'">
          <p>{{header.TITLE}}</p>        
          <label>
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'FOR_USE_WITH'">
          <p>{{header.TITLE}}</p>        
          <label class="grid-atag" (click)="windowClickEvt(header.FIELD)">
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'MAX_PRESSURE'">
          <p>{{header.TITLE}}</p>        
          <label class="grid-atag" *ngIf="info.MAX_PRESSURE_LINK && info.MAX_PRESSURE_LINK != null" (click)="windowClickEvt(header.FIELD)">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="info.MAX_PRESSURE_LINK == null">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="info.MAX_PRESSURE == ''">
            -
          </label>
        </div>
        <div *ngIf="header.FIELD == 'MAX_TEMP'">
          <p>{{header.TITLE}}</p>        
          <label class="grid-atag" *ngIf="info.MAX_TEMP_LINK && info.MAX_TEMP_LINK != null" (click)="windowClickEvt(header.FIELD)">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="info.MAX_TEMP_LINK == null">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="info.MAX_TEMP == ''">
            -
          </label>
        </div>
        <div *ngIf="header.FIELD == 'CERTIFICATE_NUMBER'">
          <p>{{header.TITLE}}</p>        
          <label class="grid-atag" *ngIf="info.CERTIFICATE_NUMBER_LINK != null;" (click)="certDownloadData(info?.CERTIFICATE_NUMBER_LINK)">
            {{info[header.FIELD]}}
          </label>
          <label *ngIf="info.CERTIFICATE_NUMBER_LINK == null;">
            {{info[header.FIELD]}}
          </label>
        </div>
        <div *ngIf="header.FIELD == 'PRODUCT_DESCRIPTION'">
          <p>{{header.TITLE}}</p>
          <label *ngIf="info[header.FIELD] != null;else productType">
            {{info[header.FIELD]}}
          </label>
          <ng-template #productType>
            <label *ngIf="info.ITEM_DESCRIPTION != null;">
              {{info.ITEM_DESCRIPTION}}
            </label>
            <label *ngIf="info.ITEM_DESCRIPTION == null;">

            </label>
          </ng-template>

        </div>

      </div>
    </div>
  </div>
</div>

<kendo-window title="{{windowTitle}}" *ngIf="isPopupEnable" (close)="close()">
  <div class="new_window" style="width: 100%; height:100%">
    <div class="custom-loader" *ngIf="isLoader"></div>
    <iframe [src]="iFrameUrl" (load)="onLoadiFrame()"></iframe>
  </div>
</kendo-window>
<kendo-dialog title="{{errorMsgObj.errorMsgTitle}}" *ngIf="isErrorPopUpEnable" (close)="closeError('cancel')">
  <span style="font-size: 20px; font-weight: 900;">{{errorMsgObj.errorMsgLbl}}</span>
  <p>{{errorMsgObj.errorMsgTxt}}</p> 
</kendo-dialog>