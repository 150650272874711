import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, filter, lastValueFrom, takeUntil } from "rxjs";
import { BroadcastService } from "src/app/shared/services/broadcast.service";
import { ApiService } from "../shared/services/api.service";
import { AppConstants, SEARCH_RESULT_FLAG } from "../shared/constants/app.constants";
import { CommonService } from "../shared/services/common.service";

@Component({
    template: `
        <section class="page-wrapper">
            <app-header></app-header>
            <span class="title">{{msg}}</span>
            <app-footer></app-footer>
        </section>
        `,
    styles: [
        `section.page-wrapper {
                height: 100%;
                display: grid;
                grid-template-rows: auto 1fr auto;
                place-items: center;
                span.title {
                    font-size : xx-large;
                }
            }`
    ]
})
export class ShareLinkPage implements OnInit, OnDestroy {

    destroy$ = new Subject<void>();
    msg = 'Please wait ...';
    constructor(
        private readonly broadcast: BroadcastService,
        private readonly router: Router,
        private readonly actRoute: ActivatedRoute,
        private readonly apiSvc: ApiService,
        private readonly commonSvc: CommonService,
    ) {
        broadcast.isLoader.next(false);
    }


    ngOnInit(): void {
        this.actRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe({
            next: async (params) => {
                try {


                    const seq = params['seq'];
                    if (!seq) {
                        this.msg = 'Broken or invalid link!'
                        return
                    }
                    const response = await lastValueFrom<any>(this.apiSvc.get(this.apiSvc.urls.getShareableLink + `=${seq}`, this.apiSvc.getaccessToken()));
                    if (!response?.success) {
                        this.msg = 'Broken or invalid link!'
                        return;
                    }
                    const dataEncoded = response.data.shareLinkDetails;
                    const dataDecoded = JSON.parse(this.commonSvc.base64ToJson(dataEncoded));
                    sessionStorage.setItem('sectionData', JSON.stringify(dataDecoded.sectionData));
                    sessionStorage.setItem('advFilter', JSON.stringify(dataDecoded.advFilter));
                    const targetPage = dataDecoded.type as 'searchResult' | 'productDetails' | 'modelDetails' | 'categoryDetails';
                    let targetPageUrl = '';
                    switch (targetPage) {
                        case 'productDetails':
                            sessionStorage.setItem(AppConstants.CLEAR_FORM, 'Y')
                            sessionStorage.setItem(SEARCH_RESULT_FLAG, 'Y')
                            targetPageUrl = 'certified-products-systems/product-details'
                            break;
                        case 'categoryDetails':
                            sessionStorage.setItem(AppConstants.CLEAR_FORM, 'Y')
                            sessionStorage.setItem(SEARCH_RESULT_FLAG, 'Y')
                            targetPageUrl = 'certified-products-systems/category-details'
                            break;
                        case 'modelDetails':
                            sessionStorage.setItem(AppConstants.CLEAR_FORM, 'Y')
                            sessionStorage.setItem(SEARCH_RESULT_FLAG, 'Y')
                            targetPageUrl = 'certified-products-systems/model-details'
                            break;
                        case 'searchResult':
                            sessionStorage.setItem('dynamicForm', JSON.stringify(dataDecoded.dynamicForm));
                            sessionStorage.setItem(AppConstants.CLEAR_FORM, 'keep-data')
                            targetPageUrl = 'certified-products-systems/search-result'
                            break;
                    }
                    sessionStorage.setItem(AppConstants.HAS_SHARE_LINK, 'Y');
                    sessionStorage.setItem(AppConstants.SHARE_LINK_DATA, response.data.shareLinkDetails);
                    await this.router.navigateByUrl(targetPageUrl);
                }
                catch (err) {
                    await this.router.navigateByUrl('not-found');

                }
            }
        })
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}