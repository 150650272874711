import { Component, Input } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { ApiService } from '../../services/api.service';
import { BroadcastService } from '../../services/broadcast.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-info',
  templateUrl: './detail-info.component.html',
  styleUrls: ['./detail-info.component.scss']
})
export class DetailInfoComponent {
  readonly constants = AppConstants;
  public ENV = environment;
  public serviceURL = this.ENV.serviceURL;
  @Input() headers: any;
  @Input() info: any;
  @Input() colStyle: any;
  contacts: any;

  detailList: any = [];
  isPopupEnable: any = false;
  iFrameUrl: SafeResourceUrl;
  isLoader: boolean = false;
  isErrorPopUpEnable:any = false;
  public errorMsgObj = {
    errorMsgTitle : "",
    errorMsgLbl: "",
    errorMsgTxt : ""
  }
  windowTitle:string;

  constructor(private _api: ApiService,
    private _broadcast: BroadcastService,private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    // this.getdetailInfo();    
    this.serviceURL = this.serviceURL.slice(0, -1);
    this.contacts = this.info?.CONTACT?.split("\r");
    this.info.NAME = this.info?.NAME?.split("\r");
    let maxColOrderSeq = this.headers[this.headers.length-1].COL_ORDER_SEQ;
   // this.headers = this.orderBySeq(this.headers);
   // console.log("this.headers b4 = ", this.headers);
   for(var i=0;i < maxColOrderSeq;i++) {
    if(this.headers[i].COL_ORDER_SEQ <= i+1  ) {
      
    } else {
      let header = {
        COL_ORDER_SEQ : i+1,
        FIELD: "",
        TITLE: ""
      }
      this.headers.splice(i,0,header);      
    }    
   }
    this.headers.sort((a: any, b: any) => {
      return a.COL_ORDER_SEQ - b.COL_ORDER_SEQ;
    });
    // console.log("this.headers after = ", this.headers);
  }

  getdetailInfo() {
    const header = this._api.getPlainHeader();
    this._api.get(this._api.urls.getDetailInfoUrl, header).subscribe((res: any) => {
      this.detailList = res.data[0];
    });
  }


  goToWebsite(url: any) {
    url = 'https://' + url;
    window.open(url, "_blank");
  }

  windowClickEvt(type:any) { 
    if ((type == "END_USES") || (type =="FOR_USE_WITH")){
      let getEndUse  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');     
      this.windowTitle = this.constants.WINDOW_TILE_END_USE;
      let url =  getEndUse?.PUBLIC_LISTINGS_ENDUSE;
      this.isLoader = true;
      this.isPopupEnable = true;
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); 
    }else if (type == "MAX_PRESSURE"){
      let getEndUse  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');    
      // 14473 - this.windowTitle = this.info?.STD_PROGRAM_GRP +" "+ this.constants.WINDOW_TILE_HIGH_PRESSURE; 
      this.windowTitle = this.constants.WINDOW_TILE_HIGH_PRESSURE;
      let url =  getEndUse?.PUBLIC_LISTINGS_HIGHLOW_PRESSURE;
      this.isLoader = true;
      this.isPopupEnable = true;
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); 
    } else if (type == "WATER_CONTACT_TEMP"){
      let getEndUse  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');    
      // 14473 - this.windowTitle = this.info?.STD_PROGRAM_GRP +" "+ this.constants.WINDOW_TILE_HIGH_PRESSURE; 
      this.windowTitle = this.constants.WINDOW_TILE_WATER_CONT_TEMP;
      let url =  getEndUse?.PUBLIC_LISTINGS_WCT;
      this.isLoader = true;
      this.isPopupEnable = true;
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); 
    } else if (type == "WATER_CONTACT_MATERIAL"){
      let getEndUse  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');    
      // 14473 - this.windowTitle = this.info?.STD_PROGRAM_GRP +" "+ this.constants.WINDOW_TILE_HIGH_PRESSURE; 
      this.windowTitle = this.constants.WINDOW_TILE_WATER_CONT_MATERIAL;
      let url =  getEndUse?.PUBLIC_LISTINGS_WCM;
      this.isLoader = true;
      this.isPopupEnable = true;
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); 
    }else{
      let getEndUse  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');   
       // 14473 - this.windowTitle = this.info?.STD_PROGRAM_GRP +" "+ this.constants.WINDOW_TILE_LOW_PRESSURE;   
      this.windowTitle = this.constants.WINDOW_TILE_LOW_PRESSURE;  
      let url =  getEndUse?.PUBLIC_LISTINGS_HIGHLOW_PRESSURE;
      this.isLoader = true;
      this.isPopupEnable = true;
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); 
    } 
      
  }

  certDownloadData(fileName:any){   
    let getBaseUrl  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');   
    this.downloadSeLink(getBaseUrl?.PUBLIC_LISTINGS_CERTIFICATES + fileName, fileName) 
 }

 downloadSeLink(url:any, filename:any) {
  fetch(url)
  .then(async(response) => {
      // status 404 or 500 will set ok to false
      if (response.ok) {
          // Success: convert data received & run callback
          const result = await response.blob().then(blob =>{
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          });
          callback(result);
      }
      else {
        this.isErrorPopUpEnable = true;
        this.errorMsgObj.errorMsgTitle = "404: File Not Found"
        this.errorMsgObj.errorMsgLbl = "We are sorry!"
        this.errorMsgObj.errorMsgTxt = `The file you have selected is not currently available to download.
        We are actively working to resolve this issue, please kindly check back later.`
        //throw new Error(response.status + " Failed Fetch ");
      }
  }).catch(e => "")
}

public closeError(status: string): void {
  // console.log(`Dialog result: ${status}`);
  this.isErrorPopUpEnable = false;
}

  close(): void {
    this.isPopupEnable = false;
  }

  onLoadiFrame() {
    this.isLoader = false;
  }
}
function callback(result: void) {
  throw new Error('Function not implemented.');
}

