import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../services/broadcast.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit{
  private subscriptions = new Subscription();
  message: string = '';
  iShowError: boolean = false;

  constructor(private _broadcast: BroadcastService) { }

  ngOnInit(): void {
    this.subscriptions.add(this._broadcast.getErrorMessage().subscribe((data: any) => {
      this.message = data.message;
      this.iShowError = data.iShowError;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
