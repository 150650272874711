<kendo-formfield [formGroup]="formGroup" orientation='horizontal' class="formField-div">
  <div role="text">
    <kendo-label [for]="field.field_name" [text]="field.title? field.title : field.field_heading"
      class="input-textbox-label">
      <span *ngIf="field.mandatory_flg === 'Y'" class='primaryIndicator'>*</span>
    </kendo-label>
  </div>

  <kendo-datepicker class="dynamicTextbox resize-none" [calendarType]="calenderType" [id]="field.field_name"
    aria-expanded="true" ngDefaultControl [formControlName]="field.field_name" [format]="dateFormat"
    placeholder="DD-MMM-YYYY" [ngClass]="{'bold': formGroup.value[field.field_name]}"
    [value]="field.field_value" (valueChange)="valueChange($event,field)" [min]="field?.min">
  </kendo-datepicker>
</kendo-formfield>
