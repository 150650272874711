<kendo-formfield [formGroup]="formGroup" orientation="horizontal" class="formField-div">  
  <div role="text">
    <kendo-label [for]="field.field_name" [text]="field.field_heading" class="input-textbox-label"><span
        *ngIf="field.mandatory_flg === 'Y'" class='primaryIndicator'>*</span>
    </kendo-label>
  </div>
  <kendo-textbox class="dynamicTextbox" [id]="field.field_name" [formControlName]='field.field_name'
    [ngClass]="{'bold': formGroup.value[field.field_name],'disableField': (field.isEditable === 'N' || isFieldDisabled === true )}" [value]="field.field_value"
    (valueChange)="valueChange($event,field)"
    (keypress)="keyDownFunction($event, field.field_name)" (paste)="onPaste(field.field_heading, $event)">
  </kendo-textbox>
</kendo-formfield>
