import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  public isLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateAdvForm: Subject<Object> = new Subject<Object>();
  public updateCatSubCatFields: Subject<Object> = new Subject<Object>();
  public updateFilters: Subject<Object> = new Subject<Object>();

  public formOutputValues: Subject<Object> = new Subject<Object>();
  public checkHeight: Subject<Object> = new Subject<Object>();
  public errorMessage: Subject<Object> = new Subject<Object>();
  public noDataImage: Subject<Object> = new Subject<Object>();
  public redirectData: Subject<Object> = new Subject<Object>();
  public isAdvancedSearchClick: Subject<Object> = new Subject<Object>();
  public isexpandCollapse: Subject<Object> = new Subject<Object>();
  public searchResults : BehaviorSubject<Object> = new BehaviorSubject<Object>(false);
  public clearFilter : BehaviorSubject<Object> = new BehaviorSubject<Object>(false);
  /*** subject observable for category, subcategory & standard data load *** */
  public isStaticDataLoaded: Subject<boolean> = new Subject<boolean>();
  /*** subject observable to load dynamic data based on standard data  *** */
  public isDataUrlInvoked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoadedWithDataUrl: boolean = false;

  constructor() {
    
   }

  showLoader(value: boolean) {
    this.isLoader.next(value);
  }

  updateAdvFormFn(value: Object) {
    this.updateAdvForm.next(value);
  }

  getUpdatedAdvFormFn() {
    return this.updateAdvForm.asObservable();
  }

  setCatSubCatFields(value : Object) {
    this.updateCatSubCatFields.next(value);
  }

  getCatSubCatFields() {
    return this.updateCatSubCatFields.asObservable();
  }

  setFilters(value : Object) {
    this.updateFilters.next(value);
  }

  getFilters() {
    return this.updateFilters.asObservable();
  }

  setUpdateFiltersData(value : Object) {
    this.formOutputValues.next(value);
  }

  getUpdateFiltersData() {
    return this.formOutputValues.asObservable();
  }

  setHeight(value : Object) {
    this.checkHeight.next(value);
  }

  getHeight() {
    return this.checkHeight.asObservable();
  }

  setErrorMessage(value : Object) {
    this.errorMessage.next(value);
  }

  getErrorMessage() {
    return this.errorMessage.asObservable();
  }

  setNoDataImage(value : Object) {
    this.noDataImage.next(value);
  }

  getNoDataImage() {
    return this.noDataImage.asObservable();
  }

  setSearchResults(value : any) {
    this.searchResults.next(value);
  }

  getSearchResults() {
    return this.searchResults.asObservable();
  }
  
  setClearFilter(value : any) {
    this.clearFilter.next(value);
  }

  getClearFilter() {
    return this.clearFilter.asObservable();
  }

  setRedirect(value : any) {
    this.redirectData.next(value);
  }

  getRedirect() {
    return this.redirectData.asObservable();
  }

  setAdvancedSearchClick(value : any) {
    this.isAdvancedSearchClick.next(value);
  }

  getAdvancedSearchClick() {
    return this.isAdvancedSearchClick.asObservable();
  }

  setExpandFlag(value : any) {
    this.isexpandCollapse.next(value);
  }

  getExpandFlag() {
    return this.isexpandCollapse.asObservable();
  }
 


}
