<div class="flex">
    <header>
      <!-- <app-header></app-header> -->
    </header>
    <div class="container max-w-screen-xxl">
      <!-- <div class="subHeader">
        <app-main-header [classicViewUrl]="classicViewUrl"></app-main-header>
      </div> -->
      <section class="not-fount__wrapper">
        <p class="red-label">We are sorry!</p>
        <div class="four-o-4">404</div>
        <p>The Certified Products and Systems page you are looking for is no longer valid/available.</p>
        <p>Please go back Certified Products and Systems homepage and try a different search.</p>
        <!-- <a class="home-page-link" (click)="backToHome()">Back to Homepage</a> -->
      </section>
    </div>
    <!-- <footer>
      <app-footer></app-footer>
    </footer> -->
  </div>