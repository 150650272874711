<div class="resultHeader gridMargin"  (click)="isExpand = !isExpand;"
[ngClass] = "isExpand ? 'resultHeaderExpand' : 'resultHeaderCollapsed'">
    <div class="liDimension">
      <label *ngIf="searchResultHeaderInfo?.subCategory" class="litext">{{searchResultHeaderInfo.subCategory}} -&nbsp;</label>
      <label class="litext"> {{searchResultHeaderInfo.stdProgGroup}} - {{searchResultHeaderInfo.standard}}</label>
    </div>
    <div class="right">
      <img 
      [src]="isExpand ? 'assets/images/normal_arrow1_u2012.svg' : 'assets/images/expand_less_white.svg'" 
              alt="expand-icon" 
              title="Search Deatils" filter="img"> 
    </div>
  </div>