import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
  name: 'highlight'
})
export class HightlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any, searchText: any): any {
    if (!searchText) {
      return value;
    }
    // replace/escape the special character.
    searchText = searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    // Match in a case insensitive maneer
    const re = new RegExp(searchText, 'gi');
    const match = value.match(re);

    // If there's no match, just return the original value.
    if (!match) {
      return value;
    }

    const replacedValue = value.replace(re, "<span class='hightlight'>" + match[0] + "</span>")
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue)
  }
}
