import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-check-box',
  templateUrl: './input-check-box.component.html',
  styleUrls: ['./input-check-box.component.scss']
})
export class InputCheckBoxComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field: any;
  @Output() formGroupValue = new EventEmitter();
  label: any;
  isFieldDisabled: boolean = false;

  ngOnInit(): void {
    this.isFieldDisabled = this.formGroup.controls[this.field?.field_name].disabled;
  }
  valueChange(event: any, currentField: any): void {
    this.formGroupValue.emit({
      "currentFieldValue": event,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": currentField
    });
  }

}
