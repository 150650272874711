import { Component, OnInit, EventEmitter, Input, Output, HostListener, ViewChild, ElementRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PopoverContainerDirective } from "@progress/kendo-angular-tooltip";
import { MultiSelectComponent } from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-input-multi-select',
  templateUrl: './input-multi-select.component.html',
  styleUrls: ['./input-multi-select.component.scss']
})
export class InputMultiSelectComponent implements OnInit {
  @ViewChild("multiselect") public multiselect: MultiSelectComponent;
  dropdownField: any;
  defaultVal: any;
  @Input() field: any;
  @Input() formGroup!: FormGroup;
  @Input() isUpdateFields: number = 0;
  @Output() formGroupValue = new EventEmitter();

  @ViewChild("container", { static: false })
  private container: PopoverContainerDirective;
  @ViewChild("head", { read: ElementRef }) public head: ElementRef;
  @ViewChild("body", { read: ElementRef }) public body: ElementRef;
  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef;
  fieldValue: any = [];
  public defaultItem: any;
  states: any = [];
  temp: any = [];
  label: any;
  dropDowndata: any = [];
  selectDeSelectAll = 'Select All';
  public isDisabled: boolean = false;

  constructor(
    private cd: ChangeDetectorRef
  ) { }


  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.container?.hide();
    }
  }

  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.container?.hide();
    }
  }

  private contains(target: any): boolean {
    return (
      this.head?.nativeElement?.contains(target) ||
      this.anchor?.nativeElement?.contains(target) ||
      target.classList[0] === 'prevent' ||
      (this.body ? this.body?.nativeElement?.contains(target) : false)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isUpdateFields'] && !changes['isUpdateFields'].firstChange) {
      this.cd.detectChanges();
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.fieldValue = this.field.field_value;
    this.dropDowndata = this.field?.dropdown_list?.slice() || [];
    (this.fieldValue?.length === this.dropDowndata.length) ? this.selectDeSelectAll = 'Deselect All' : this.selectDeSelectAll = 'Select All';
    this.updateSessionStorage(this.fieldValue);
    this.defaultItem = {
      value: 'Select',
      key: null,
    };
    if (sessionStorage.getItem('states')) {
      this.states = sessionStorage.getItem('states') ? JSON.parse(sessionStorage.getItem('states') || '{}') : [];
    }
    if (this.field?.field_name === 'state'){
      if (this.field?.dropdown_list === null || this.field?.dropdown_list === undefined || this.field?.dropdown_list?.length === 0) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    }
  }

  public toggleAllText(data: any, fieldValue: any) {
    let count = 0;
    data.forEach((ele: any) => {
      if(this.checkvalue(ele.key, fieldValue)) {
        count++;
      }
    });
    (data.length === count) ? this.selectDeSelectAll = 'Deselect All' : this.selectDeSelectAll = 'Select All';
  }

  checkvalue(key: any, fieldValue: any = []) {
    let flag = false;
    if(fieldValue && fieldValue.length !== 0) {
      fieldValue?.forEach((ele:any) => {
        if(ele.key === key) {
          flag = true;
        }
      });
    }
    return flag;
  }

  public isItemSelected(item: any) {
    let value =  this.formGroup.value[this.field.field_name] || [];
    return value.some((x: any) => x.key === item.key);
  }

  public onClick() {
    // this.isChecked = !this.isChecked;
    let value = this.dropDowndata.slice();
    if(this.selectDeSelectAll === 'Select All') {
      //value = [...this.formGroup.controls[this.field.field_name].value, ...value];
      value = [...new Set(value)]
    } else {
      let curValue: any = [];
      this.formGroup.controls[this.field.field_name].value.forEach((element: any) => {
        if(value.indexOf(element) === -1) {
          curValue.push(element);
        }
      });
      value = curValue;
    }
    this.formGroup.controls[this.field.field_name].setValue(value);
    this.fieldValue = [...value];
    this.formGroupValue.emit({
      "currentFieldValue": value,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": this.field
    });
    this.toggleAllText(this.dropDowndata, this.fieldValue);
  }

  handleFilter(value: any) {
    let dropDowndata = this.field.dropdown_list.filter(
      (s: any) => s.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.dropDowndata = dropDowndata;
    this.toggleAllText(this.dropDowndata, this.fieldValue);
  }

  valueChange(event: any, currentField: any): void {
    let value =  this.formGroup.value.sport || [];
    //this.isChecked = value.length === this.dropDowndata.length;
    this.fieldValue = [...event];
    this.formGroupValue.emit({
      "currentFieldValue": event,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": currentField
    });
    this.toggleAllText(this.dropDowndata, this.fieldValue);
  }

  removeData(data: any) {
    this.formGroup.value[this.field.field_name].forEach((ele: any, index: number) => {
      if (ele.key === data.key) {
        this.formGroup.value[this.field.field_name].splice(index, 1);
      }
    });
    this.fieldValue = this.formGroup.value[this.field.field_name];
    if(this.fieldValue.length === 0) {
      this.formGroup.controls[this.field.field_name].setValue([]);
    }

    this.updateSessionStorage(this.fieldValue);

    let dynamicData = JSON.parse(sessionStorage.getItem('sectionData') || '[]');
    dynamicData.map((d: any) => {
      if(this.field.field_name === d.field_name) {
        d.field_value = this.fieldValue;
      }
    });
    sessionStorage.setItem('sectionData', JSON.stringify(dynamicData));
    this.formGroupValue.emit({
      "currentFieldValue": this.fieldValue,
      "allFieldValue": this.formGroup.value,
      "currentFieldInfo": this.field
    });
    this.toggleAllText(this.dropDowndata, this.fieldValue);
  }

  public onToggle(anchor: Element): void {
    this.container.toggle(anchor);
  }

  updateSessionStorage(fieldValue: any): void {
    let advFilter = JSON.parse(sessionStorage.getItem('advFilter') || '[]');
    if (advFilter !== null) {
      advFilter[this.field.field_name] = fieldValue;
    }
    sessionStorage.setItem('advFilter', JSON.stringify(advFilter));
  }

  /***************testing purpose ********* */
  // (show)="onShow()"
  //   (shown)="onShown()"
  //   (hide)="onHide()"
  //   (hidden)="onHidden()"


  
  // public events: string[] = [];
  // public onShow(): void {
  //   this.log("show");
  // }

  // public onShown(): void {
  //   this.log("shown");
  // }

  // public onHide(): void {
  //   this.log("hide");
  // }

  // public onHidden(): void {
  //   this.onShow();
  //   this.log("hidden");
  // }

  // private log(event: string, arg?: unknown): void {
  //   this.events.unshift(`${event} ${arg || ""}`);
  // }

}
