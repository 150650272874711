import { Component } from "@angular/core";
import { BroadcastService } from "../../services/broadcast.service";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { CommonService } from "../../services/common.service";

@Component({
  template: `
<div class="flex">
  <header>
    <app-header></app-header>
  </header>
  <div class="container max-w-screen-xxl">
    <!-- <div class="subHeader">
      <app-main-header [classicViewUrl]="classicViewUrl"></app-main-header>
    </div> -->
    <section class="not-fount__wrapper">
      <p class="sorry-label">We are sorry!</p>
      <div class="four-o-4">404</div>
      <p>The Certified Products and Systems page you are looking for is no longer valid/available.</p>
      <p>Please go back Certified Products and Systems homepage and try a different search.</p>
      <a class="home-page-link" (click)="backToHome()">Back to Homepage</a>
    </section>
  </div>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
    `,
  styles: [
    `.not-fount__wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                height: 580px;
                margin: 120px auto;
                width: 930px;
            }`,
    `.sorry-label {
            color: #14244E;
            font-weight: bold;
            font-size: 22px;
            margin: 0;
        }`,
    `.four-o-4 {
            font-size: 170px;
            color: rgba(255, 105, 57, 0.75);
            font-weight: bold;
        }`,
    `p {
      color: #14244E;
      font-size:14px;
      font-weight: 400;
      margin-bottom: 20px;
    }`,
    `.home-page-link {
            background-color: #214AE4;
            color: #fff;
            text-decoration: none;
            color: white;
            padding: 10px 20px;
            border-radius: 6px;
            cursor: pointer;
        }`,

    `@media screen and (max-width: 930px) {
      .not-fount__wrapper {
        width: 100%;

        .sorry-label {
          text-align: center;
        }

        p {
          width: 90%;
          margin: 0 auto 20px auto;
        }
      }
    }`

  ]

})
export class NotFoundPage {
  classicViewUrl: string | null = null;

  constructor(private _broadcast: BroadcastService,
    private router: Router,
    public commonservice: CommonService,
    private _api: ApiService) {
    this.getEnvInfo();
  }

  getEnvInfo() {
    const header = this._api.getPlainHeader();
    this._broadcast.showLoader(true);
    this._api.get(this._api.urls.getEnvInfoUrl, header).subscribe((res: any) => {
      if (res.success && res.data) {
        this.commonservice.apiDateTime = res.data;
        this.classicViewUrl = res.data.PUBLIC_LISTINGS_CLASSIC_VIEW_HDR ?? null;
        this._broadcast.showLoader(false);
      }
    }, (err) => {
      // console.log(err);
    });
  }

  backToHome() {
    sessionStorage.clear();
    this.router.navigateByUrl("certified-products-systems");
  }
}
