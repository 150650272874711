<kendo-formfield [formGroup]="formGroup" orientation='horizontal' class="formField-div">
  <div role="label"> <kendo-label [for]="field.field_name" [text]="field.field_heading" class="input-textarea-label"><span
        *ngIf="field.mandatory_flg === 'Y'" class='primaryIndicator'>*</span></kendo-label></div>
  <kendo-textarea [formControlName]="field.field_name" [id]="field.field_name" class=" resize-none" [ngClass]="{'bold': formGroup.value[field.field_name]}"
    [value]="field.field_value" [rows]='4' aria-colspan="" (valueChange)="valueChange($event,field)"
    (paste)="onPaste(field.field_name, $event)" (keydown)="onTrim(field.field_name, $event)">
  </kendo-textarea>
  <div *ngIf="field.showHintMsg">
    <kendo-label [for]="field.hintMsgKey" [id]="field.hintMsgKey">
      {{field.hintMsgKey}}
    </kendo-label>
  </div>
</kendo-formfield>
