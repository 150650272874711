<kendo-formfield [formGroup]="formGroup" orientation="horizontal" class="formField-div">
  <div><kendo-label [text]="dropdownField.field_heading" title="datepicker" class="input-dropdown-label"><span
        *ngIf="dropdownField.mandatory_flg === 'Y'" class='primaryIndicator'>*</span></kendo-label></div>
  <kendo-dropdownlist class="dynamicDropdown newDropdown"
    [ngClass]="{'disableField': isFieldDisabled == true, 'bold': formGroup.value[dropdownField.field_name]}"
    [formControlName]="dropdownField.field_name" [defaultItem]="defaultVal" [data]="dropDowndata"
    valueField="key" textField="value" [valuePrimitive]="true" [id]="dropdownField.field_name"
    (valueChange)="valueChange($event,dropdownField)" [filterable]="true" (filterChange)="handleFilter($event)">
  </kendo-dropdownlist>

  <!-- <kendo-combobox class="dynamicDropdown newDropdown"
    [ngClass]="{'disableField': isFieldDisabled == true, 'bold': formGroup.value[dropdownField.field_name]}"
    [formControlName]="dropDowndata" placeholder="Select" [data]="dropdownField.dropdown_list"
    valueField="key" textField="value" [valuePrimitive]="true" [id]="dropdownField.field_name" [filterable]="true"
    (filterChange)="handleFilter($event)" (valueChange)="valueChange($event,dropdownField)">
  </kendo-combobox> -->
</kendo-formfield>
