<div class="row gridMargin">

  <!-- main common grid started -->
  <kendo-grid [rowClass]="rowCallback" #grid="kendoGrid" [kendoGridBinding]="gridData" [pageSize]="pageSize"
    [pageable]="pageable" [sortable]="allowSorting" [filterable]="showColumnFilter" [selectable]="true"
    [navigable]="true" [(expandedDetailKeys)]="expandedDetailKeys" [kendoGridExpandDetailsBy]="expandDetailsBy"
    (pageChange)="pageChange($event)" (filterChange)="filterChange($event)" (sortChange)="sortChange($event)"
    [sort]="sort" [filter]="filter" [skip]="skip">

    <!-- other links and buttons related to table -->
    <ng-template kendoGridToolbarTemplate>

      <div class="toolbar-block"
        [ngClass]="{'disclaimer' : !(tabelID == 'productDetails' || tabelID == 'modelDetails' || tabelID == 'categoryDetails')}">

        <div class="disclaimer-content" *ngIf="!(tabelID == 'productDetails' || tabelID == 'modelDetails' || tabelID == 'categoryDetails')">
          <span>
            {{constants.LISTING_DETAILS_MSG}}
          </span>
        </div>

        <div class="pdf-div mouse-cursor resultCountMargin" role="img">

          <div class="head-right displayFlex" *ngIf="tabelID == 'productDetails' || tabelID == 'modelDetails' ||  tabelID == 'categoryDetails'">
            <app-shared-link [type]="tabelID"></app-shared-link>
          </div>

          <span (click)="exportToPDF(grid, tabelID)">
            <img class="img-pdf" id="PDFBtn" title="PDF Download" filter="img" src="assets/images/PDF.png"
              alt="pdf-icon">
            <span class="pdfDownloadText">
              Download as PDF
            </span>
          </span>

          <span class="margin-left50" (click)="toggleAllFootnotes()"
            *ngIf="tabelID == 'productDetails' || tabelID == 'modelDetails' ||  tabelID == 'categoryDetails'">
            <span class="pdfDownloadText" *ngIf="!isExpandAllClicked">
              Expand all Footnotes
            </span>
            <span class="pdfDownloadText" *ngIf="isExpandAllClicked">
              Collapse all Footnotes
            </span>
            <img [ngClass]="isExpandAllClicked ? 'rotateimg' : ''"
              [src]="isExpandAllClicked ? 'assets/images/normal_path_3618_u13104.svg' : 'assets/images/normal_path_3618_u13104.svg'"
              alt="expand-icon" [title]="isExpandAllClicked ? 'Collapse all Footnotes' : 'Expand all Footnotes'"
              filter="img" class="marginLeft10">
          </span>
        </div>
      </div>
    </ng-template>

    <!-- web browser table grid content template start -->
    <ng-container *ngFor="let column of GridColumns">

      <!-- kendo grid column main content starts from here -->
      <kendo-grid-column [field]="column.FIELD" [columnMenu]="column.ShowMenu == false? false: true"
      [filterable]="true" [title]="column.TITLE" [width]="column.HTML_WIDTH"
      [sortable]="column.SORTABLE == false? false: true" [class]="{'codeColumn': (column.FIELD == 'MODEL' && GridColumns.length> 5) ,
      'footnoteColumn': column.FIELD == 'RELATED_FOOTNOTES'}">

        <!-- sort the data based on table header as column -->
        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
          {{ column.title }}
          <span [ngClass]="column?.title != 'View Listing Details' ? 'k-icon k-i-arrows-swap' : '' "></span>
        </ng-template>

        <!-- set the data of table as row -->
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

          <p class="txtBreak"
           *ngIf="(dataItem[column.FIELD] != '' && dataItem[column.FIELD] != null) && column?.FIELD != 'MODEL'&& column?.FIELD !='MODEL_NUMBER' && column?.FIELD !='SUCTION_OUTLET_FITTING_MODEL' && column?.FIELD != 'TRADE_NAME' && column?.FIELD != 'WATER_CONTACT_MATERIAL' && column?.FIELD != 'WATER_CONTACT_TEMP' && column?.FIELD != 'END_USES' && column?.FIELD != 'FOR_USE_WITH' && column?.FIELD != 'MAX_PRESSURE' && column?.FIELD != 'MAX_TEMP' && column?.FIELD != 'CERTIFICATE_NUMBER' && column?.FIELD!='PERFORMANCE_STANDARD' && column?.FIELD != 'PERFORMANCE_STANDARD_DISPLAY' && column?.FIELD != 'VIEW_MODEL_LISTING_DETAILS' && column?.FIELD != 'VIEW_PRODUCT_LISTING_DETAILS' && column?.FIELD != 'VIEW_LISTING_DETAILS' && column?.FIELD != 'RELATED_FOOTNOTES'  && column?.FIELD != 'TRADE_DESIGNATION' && column?.FIELD !='SUCTION_FITTING_MODEL'; else dashValue">
            <span class="txtBreak" *ngIf="isSearchHighlight; else noHighlight" [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
            <ng-template #noHighlight>
              {{dataItem[column.FIELD]}}
            </ng-template>
          </p>

          <ng-template #dashValue>
            <p *ngIf="(dataItem[column.FIELD] == null || dataItem[column.FIELD] == '') && (column?.FIELD != 'VIEW_MODEL_LISTING_DETAILS' && column?.FIELD != 'VIEW_PRODUCT_LISTING_DETAILS' && column?.FIELD != 'VIEW_LISTING_DETAILS' && column?.FIELD != 'RELATED_FOOTNOTES')">
              -
            </p>
          </ng-template>

          <p *ngIf="column?.FIELD == 'TRADE_DESIGNATION' && column?.IsClickField != true">
            <img *ngIf="dataItem.LOGO_URL != null" class="imgDimensions" [src]="dataItem.LOGO_URL" onerror="this.style.display='none'"/>
            <span *ngIf="dataItem.LOGO_URL == null" class="wrap_line">
              <span *ngIf="isSearchHighlight; else noHighlight"
                [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
              <ng-template #noHighlight>
                {{dataItem[column.FIELD]}}
              </ng-template>
            </span>
          </p>

          <p class="download"
            *ngIf="(column?.FIELD == 'VIEW_PRODUCT_LISTING_DETAILS' || column?.FIELD == 'VIEW_MODEL_LISTING_DETAILS' || column?.FIELD == 'VIEW_LISTING_DETAILS')">
            <img [src]="'assets/images/Asset3mdpi.png'" [alt]="column.FIELD" class="listingDetails"
            (click)="sendClickedData(dataItem.SEQ, dataItem[column?.FIELD], column?.FIELD,'',dataItem)"
              style="cursor:pointer; margin-left: 30%;" id="{{column.FIELD}}Icon">
          </p>

          <div *ngIf="dataItem[column.FIELD] != null && column?.FIELD === 'MODEL' && column?.IsClickField !=true">

            <p *ngIf="isViewModel(dataItem[column.FIELD])">
              <a (click)="addViewModelData(dataItem.PRO_SEQ, dataItem.isclicked = !dataItem.isclicked, dataItem.MODEL);"
                class="table-ancher">
                <span [ngClass]="dataItem.isclicked ? 'modelLabelClicked' : ''">View Models</span>
                <img [ngClass]="dataItem.isclicked ?  'imgclicked' : ''"
                  [src]="dataItem.isclicked? 'assets/images/normal_path_3618_u13104.svg' : 'assets/images/normal_path_3618_u13104.svg'"
                  alt="expand-icon" title="View Models" filter="img">
              </a>
              <span [ngClass]="dataItem.isclicked ? 'modelLabelCount' : 'modelLabelCount_normal'" class="block"
                [ngBind]="calcViewModelCount(dataItem, dataItem[column.FIELD])">
                {{dataItem.viewModelCount}} <span
                  [ngClass]="dataItem.isclicked ? 'modelLabeltext' : 'modelLabeltext_normal'"> Models exists</span>
              </span>
            </p>

            <p *ngIf="!isViewModel(dataItem[column.FIELD])">
              <span class="txtBreak" *ngIf="isSearchHighlight; else noHighlight"
                [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
              <ng-template #noHighlight>
                {{dataItem[column.FIELD]}}
              </ng-template>
            </p>
          </div>

          <div
            *ngIf="dataItem[column.FIELD] != null && (column?.FIELD === 'MODEL' || column?.FIELD === 'MODEL_NUMBER' || column?.FIELD === 'SUCTION_OUTLET_FITTING_MODEL' || column?.FIELD === 'FOR_USE_WITH' || column?.FIELD === 'TRADE_NAME' || column?.FIELD === 'TRADE_DESIGNATION' || column?.FIELD === 'SUCTION_FITTING_MODEL') && column?.IsClickField == true">
            <a class="grid-atag" (click)="sendClickedData(dataItem.MODEL_SEQ, dataItem['VIEW_MODEL_LISTING_DETAILS'], column?.FIELD, dataItem[column?.FIELD],dataItem)">
              <span class="txtBreak" [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
            </a>           
          </div>
          <div *ngIf="dataItem[column.FIELD] != null && column?.FIELD === 'MODEL_NUMBER' && column?.IsClickField !=true">
            <span  [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
          </div>
          <div *ngIf="dataItem[column.FIELD] != null && column?.FIELD === 'SUCTION_OUTLET_FITTING_MODEL' && column?.IsClickField !=true">
            <span class="txtBreak" [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
          </div>
          <div *ngIf="dataItem[column.FIELD] != null && column?.FIELD === 'SUCTION_FITTING_MODEL' && column?.IsClickField !=true">
            <span class="txtBreak" [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
          </div>
          <div *ngIf="dataItem[column.FIELD] != null && column?.DATA_TYPE === 'HYPERLINK' && (column?.FIELD === 'END_USES' ||  column?.FIELD === 'WATER_CONTACT_TEMP' ||  column?.FIELD === 'WATER_CONTACT_MATERIAL') && column?.IsClickField == true">
            <a class="grid-atag" (click)="sendClickedData(dataItem.MODEL_SEQ, dataItem['VIEW_MODEL_LISTING_DETAILS'], column?.FIELD, dataItem[column?.FIELD],dataItem)">
              <span class="txtBreak" [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
            </a>
          </div>

          <div *ngIf="dataItem[column.FIELD] != null && column?.DATA_TYPE != 'HYPERLINK' && (column?.FIELD === 'END_USES' ||  column?.FIELD === 'WATER_CONTACT_TEMP' ||  column?.FIELD === 'WATER_CONTACT_MATERIAL') && column?.IsClickField == true">
                <span class="txtBreak" [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
          </div>

          <div *ngIf="dataItem[column.FIELD] != null && column?.FIELD === 'TRADE_NAME' && column?.IsClickField != true">
            <span class="txtBreak" [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
          </div>
          
          <div  *ngIf="dataItem[column.FIELD] != null && (column.FIELD == 'PERFORMANCE_STANDARD_DISPLAY' || column.FIELD == 'PERFORMANCE_STANDARD') && column?.IsClickField == true">
          <!-- <a class="grid-atag" *ngIf="dataItem['PERFORMANCE_STANDARD_LINK']" (click)="sendDownloadData(dataItem.PERFORMANCE_STANDARD_LINK)">
            <span [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
          </a> -->
            <a class="grid-atag" *ngIf="dataItem['PERFORMANCE_STANDARD_LINK']">
              <span *ngIf="dataItem[column.FIELD] != null">
                <span *ngFor="let prop of dataItem['PERFORMANCE_STANDARD_LINK'] let i=index">
                  <span *ngIf="prop.link == null" class="over-grid-tag" [innerHTML]="prop.name | highlight: searchText" ></span>
                  <span *ngIf="i!=dataItem['PERFORMANCE_STANDARD_LINK'].length-1">&#44;&nbsp;</span>
                  <span *ngIf="prop.link != null" [innerHTML]="prop.name | highlight: searchText" (click)="sendDownloadData(prop.link)"></span>
                </span>
              </span>
            </a>
            <span *ngIf="!dataItem['PERFORMANCE_STANDARD_LINK']" [innerHTML]="dataItem[column.FIELD]"></span>
          </div>

          <div  *ngIf="dataItem[column.FIELD] != null && column.FIELD == 'CERTIFICATE_NUMBER'  && column?.IsClickField == true">
            <a class="grid-atag" *ngIf="dataItem['CERTIFICATE_NUMBER_LINK']" (click)="certDownloadData(dataItem.CERTIFICATE_NUMBER_LINK)">
              <span [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
            </a>
            <span *ngIf="!dataItem['CERTIFICATE_NUMBER_LINK']" [innerHTML]="dataItem[column.FIELD]"></span>
          </div>

          <div *ngIf="dataItem[column.FIELD] != null && column.FIELD == 'MAX_PRESSURE'  && column?.IsClickField == true">
            <a class="grid-atag" *ngIf="dataItem['MAX_PRESSURE_LINK']" (click)="sendClickedData(dataItem.MODEL_SEQ, dataItem['VIEW_MODEL_LISTING_DETAILS'], column?.FIELD, dataItem[column?.FIELD],dataItem)">
              <span [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
            </a>
            <span *ngIf="!dataItem['MAX_PRESSURE_LINK']" [innerHTML]="dataItem[column.FIELD]"></span>
          </div>

          <div  *ngIf="dataItem[column.FIELD] != null && column.FIELD == 'MAX_TEMP'  && column?.IsClickField == true">
            <a class="grid-atag" *ngIf="dataItem['MAX_TEMP_LINK']" (click)="sendClickedData(dataItem.MODEL_SEQ, dataItem['VIEW_MODEL_LISTING_DETAILS'], column?.FIELD, dataItem[column?.FIELD],dataItem)">
              <span [innerHTML]="dataItem[column.FIELD] | highlight: searchText"></span>
            </a>
            <span *ngIf="!dataItem['MAX_TEMP_LINK']" [innerHTML]="dataItem[column.FIELD]"></span>
          </div>

          <div *ngIf="column?.FIELD === 'RELATED_FOOTNOTES'">

            <p *ngIf="showOnlyModelDetails(dataItem); else nocontent">
              <a class="table-ancher" (click)="viewModelEvent(dataItem);">
                <span [ngClass]="dataItem.isclicked ? 'modelLabelClicked' : ''">Footnotes</span>
                <img [ngClass]="dataItem.isclicked ?  'imgclicked' : ''"
                  [src]="dataItem.isclicked? 'assets/images/normal_path_3618_u13104.svg' : 'assets/images/normal_path_3618_u13104.svg'"
                  alt="expand-icon" title="Footnotes" filter="img">
              </a>
            </p>

            <ng-template #nocontent>
              <p>
                -
              </p>
            </ng-template>

          </div>
        </ng-template>

      </kendo-grid-column>
    </ng-container>
    <!-- web browser table grid content template end -->

    <!-- foot note table -->
    <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="showOnlyModelDetails">
      <section class="footnote_sec">
        <div>
          <pre>{{dataItem.RELATED_FOOTNOTES}}</pre>
        </div>        
      </section>
    </ng-template>

    <!-- table pagination started -->
    <ng-template kendoPagerTemplate>
      <div *ngIf="totalRecords > pageSize && ( tabelID != 'modelDetails' && tabelID != 'categoryDetails')"
        class="pagerClass">
        <div class="d-flex align-items-center">
          <span style="padding-right: 10px;">Show</span>
          <kendo-pager-page-sizes [pageSizes]="pageSizes" (pageSizeChange)="onPageSizeChange($event)">
          </kendo-pager-page-sizes>
          <kendo-grid-messages pagerItemsPerPage="Items"></kendo-grid-messages>
        </div>
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-prev-buttons class="borderClass"></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons class="borderClass" [buttonCount]="3"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons class="borderClass"></kendo-pager-next-buttons>
      </div>

      <div *ngIf="totalRecords < pageSize && (tabelID != 'productDetails' && tabelID != 'modelDetails' && tabelID != 'categoryDetails')"
        class="pagerClass">
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-info></kendo-pager-info>
      </div>
    </ng-template>

    <kendo-grid-pdf fileName="searchResults.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
      [landscape]="true">
    </kendo-grid-pdf>
  </kendo-grid>

  <!-- kendo window for iframe outside the table -->
  <kendo-window title="{{windowTitle}}" *ngIf="isPopupEnable" (close)="close()">
    <div class="new_window" style="width: 100%; height:100%">
      <div class="custom-loader" *ngIf="isLoader"></div>
      <iframe [src]="iFrameUrl" (load)="onLoadiFrame()"></iframe>
    </div>
  </kendo-window>

  <!-- kendo popup for error display outside the table -->
  <kendo-dialog title="{{errorMsgObj.errorMsgTitle}}" *ngIf="isErrorPopUpEnable" (close)="closeError('cancel')" [minWidth]="350" [width]="500">
    <span style="font-size: 20px; font-weight: 900;">{{errorMsgObj.errorMsgLbl}}</span>
    <p>{{errorMsgObj.errorMsgTxt}}</p>
  </kendo-dialog>
</div>
