import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { BroadcastService } from '../../services/broadcast.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SEARCH_HOME, SEARCH_RESULTS, SEARCH_RESULT_FLAG } from '../../constants/app.constants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() curPageName: any;
  @Input() breadCrumbValue: BreadCrumbItem[];
  private subscriptions = new Subscription();
  public items: BreadCrumbItem[] = [];
  emitValue: any;
  viewListingDetails: any;


  constructor(private _broadcast: BroadcastService, private router: Router) { }

  // To Do

  ngOnInit() {
    this.items = this.breadCrumbValue;
  }

  ngOnChanges() {
    if (this.breadCrumbValue != undefined)
      this.items = this.breadCrumbValue;
  }

  public onItemClick(item: BreadCrumbItem): void {
    let getBcValues = JSON.parse(sessionStorage.getItem("BCitems") || '{}');
    if (item.text == "Product Listing Details" || item.text == getBcValues.text) {
      this.router.navigateByUrl("certified-products-systems/product-details");
    } else if (item.text == SEARCH_RESULTS || item.text == SEARCH_HOME) {
      if (sessionStorage.getItem(SEARCH_RESULT_FLAG) === 'Y') {
        sessionStorage.removeItem(SEARCH_RESULT_FLAG)
        sessionStorage.removeItem('advFilter');
        sessionStorage.removeItem('searchfeild');
        sessionStorage.removeItem('dynamicForm');
        sessionStorage.removeItem('filteredDataVal');
        sessionStorage.removeItem('sectionData');
        sessionStorage.removeItem('gridFilter');
        this.router.navigateByUrl("certified-products-systems");
      } else {
        this.router.navigateByUrl("certified-products-systems/search-result");
      }

    } else if (item.text == "Model Listing Details") {
      this.router.navigateByUrl("certified-products-systems/model-details");
    }
    const index = this.items.findIndex((e) => e.text === item.text);
    this.items = this.items.slice(0, index + 1);
  }

}
