<div class="sharePagePopup floatRight" [ngClass]="{'isNoMargin': type !== 'searchResult'}">
  <span #sharePage class="paddingSection" [ngClass]="isPopupEnabled ? 'isPopupEnabled' : ''" (click)="openPopup(!isPopupEnabled)">
    <img [src]="'assets/images/share.svg'" alt="expand-icon" filter="img" class="shareIcon">
    <label class="sharemsg">
      {{constants.SHARE_PAGE}}
    </label>
  </span>
  <kendo-popup #popup [anchor]="sharePage" *ngIf="isPopupEnabled" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign">
    <div class="popupPadding popup-block">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 popupDivPadding displayGrid">
        <label>
          Website URL
        </label>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 popupDivPadding8 displayFlex">
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 input-field">
          <span (contextmenu)="false">{{sharedLink}}</span>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pointCursor">
          <img [src]="'assets/images/copy_link.svg'" (click)="onCopyBtnClick()"
            alt="expand-icon" filter="img" class="marginLeft10">
        </div>
      </div>

      <div class="mytextdiv">
        <div class="divider"></div>
        <div class="mytexttitle">
          Or
        </div>
        <div class="divider"></div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 popupDivPadding8 displayGrid share-via">
        <label>
          Share via
        </label>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 popupDivPadding displayFlex applist">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 app">
          <img [src]="'assets/images/mail.svg'" (click)="sendUrl('mail')" alt="expand-icon"
            filter="img" class="email">
            <span>Email</span>
        </div>
        <div *ngIf="mobileType === 'android' || mobileType === 'ios'" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 app">
          <img (click)="sendUrl('sms')" [src]="'assets/images/msg.svg'" alt="expand-icon" filter="img"
          class="marginLeft10 text">
          <span>Text</span>
        </div>
      </div>
    </div>
  </kendo-popup>
</div>
