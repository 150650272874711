import { Component, Input } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../services/broadcast.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {
  private subscriptions = new Subscription();
  disclaimerType: string = 'isDefault';
  readonly constants = AppConstants;

  @Input('classicViewUrl') classicViewUrl : string | null = null;

  constructor(private _broadcast: BroadcastService,
    public commonservice: CommonService) {
  }

  ngOnInit() {
    this.subscriptions.add(this._broadcast.getRedirect().subscribe((data: any) => {
      switch (data) {
        case 'search-result':
          this.disclaimerType = 'isSearch';
          break;
        case 'product-details':
          this.disclaimerType = 'ProductDetails';
          break;
        case 'model-details':
          this.disclaimerType = 'ProductDetails';
          break;
        default:
          this.disclaimerType = 'isDefault';
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
