import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPage } from './shared/component/not-found-page/not-found.page';
import { ShareLinkPage } from './share-link/share-link.page';

const routes: Routes = [
  {
    path: 'certified-products-systems',
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path : 'share-link',
    component : ShareLinkPage
  },
  {
    path: '',
    redirectTo: 'certified-products-systems',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundPage
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
