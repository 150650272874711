import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { CommonService } from '../../services/common.service';
import { PerformsearchService } from '../../services/performsearch.service';
import { BroadcastService } from '../../services/broadcast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-radio',
  templateUrl: './search-radio.component.html',
  styleUrls: ['./search-radio.component.scss']
})
export class SearchRadioComponent implements OnInit, OnDestroy {
  readonly constants = AppConstants;
  public searchType: any = 'products';
  @Input() isSearchResults = false;
  responseMessage: string = "";
  isSearchResultAvailable: Boolean = false;
  private tabEventSubscription: Subscription = new Subscription();

  constructor(public commonservice: CommonService,
    private _performSearch: PerformsearchService,
    private _broadcast: BroadcastService) { }

  ngOnInit() {
    this.searchType = sessionStorage.getItem('searchType') || 'products';
    sessionStorage.setItem('searchType', this.searchType);
    this.commonservice.updateTabEvent(this.searchType);
    this.tabEventSubscription = this.commonservice.currentTabEvent.subscribe((searchType: string) => {
      sessionStorage.setItem('searchType', searchType);
      this.searchType = searchType;
    });
  }

  radioClick(type: string) {
    this.searchType = type;
    sessionStorage.setItem('searchType', this.searchType);    
    this.commonservice.updateTabEvent(type);
    if (this.isSearchResults) {
      this._broadcast.showLoader(true);
      this._performSearch.getSearchResults().then((response: any) => {
        this._broadcast.setClearFilter(true);
        response = response.searchResults;
        if (response.success == true && response.data != null) {
          this.isSearchResultAvailable = true;
        } else if (response.success == true && response.data == null) {
          this._broadcast.showLoader(false);
          if (response.code == null) {
            this.responseMessage = this.constants.NO_RECORDS_FOUND;
          } else {
            this.responseMessage = response.message;
          }
          this.isSearchResultAvailable = false;
          this._broadcast.setNoDataImage({ iSDefault: false });
          this.commonservice.showErrorMsg(this.responseMessage);
        } else if (response.success == false) {
          this._broadcast.showLoader(false);
          this.responseMessage = response.message;
          this.isSearchResultAvailable = false;
          this._broadcast.setNoDataImage({ iSDefault: false });
          this.commonservice.showErrorMsg(this.responseMessage);
        }
      }, (err) => {
        this.responseMessage = err.message || this.constants.EXCEEDED_MAXIMUM_RESULT;
        this.commonservice.showErrorMsg(this.responseMessage);
        this._broadcast.setNoDataImage({ iSDefault: false, message: this.responseMessage });
        this._broadcast.showLoader(false);
      });
    }
  }

  ngOnDestroy(): void {
    this.tabEventSubscription.unsubscribe();
  }

}
