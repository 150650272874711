import { Component } from '@angular/core';
import { BroadcastService } from '../../services/broadcast.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public setEnv:any;
  private subscriptions = new Subscription();
  constructor(private _broadcast: BroadcastService,private _api: ApiService){

  }

  ngOnInit(): void {   
    this.getEnvInfo();
  }

  getEnvInfo() {
    const header = this._api.getPlainHeader();  
    this._api.get(this._api.urls.getEnvInfoUrl, header).subscribe((res: any) => {
      if (res.success && res.data) {
      this.setEnv =  res.data.PUBLIC_LISTINGS_ENV;
      if((this.setEnv =="") || (this.setEnv =="PROD") || (this.setEnv == null))
      this.setEnv = null
      }else{
        this.setEnv =  res.data.PUBLIC_LISTINGS_ENV;
      }
    }, (err) => {
      // console.log(err);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
